<template>
  <section class="container pb-8 ">
    <div class="container">
      <div class="error-bg mb-3 mb-md-8">
        <div>不支援IE瀏覽器</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
